var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "div",
    { attrs: { id: "product-select-slide" } },
    [
      _c("slide-heading", { attrs: { heading: _vm.currentSlide.heading } }),
      _c("product-options-confirm-modal", {
        attrs: { bus: _vm.bus, loading: false },
        on: {
          complete: _vm.productOptionConfirmed,
          productAdded: _vm.productAddedViaModal,
        },
      }),
      _vm.loaded
        ? [
            _c("slide-image-and-description", {
              attrs: { slide: _vm.currentSlide },
            }),
            _c(
              "div",
              { staticClass: "product-grid" },
              [
                _c("div", { staticClass: "requires-attention-icon-div" }, [
                  _vm.hasThreeProducts
                    ? _c("p", { staticClass: "requires-attention-icon-text" }, [
                        _vm._v("\n          Recommended\n        "),
                      ])
                    : _vm._e(),
                ]),
                _vm._l(_vm.slideProducts, function (product, index) {
                  return _c(
                    "div",
                    {
                      key: product.id,
                      class: [
                        _vm.hasThreeProducts && index === 0
                          ? "premium-product"
                          : "free-product",
                        _vm.hasThreeProducts ? "full-width" : "standard-width",
                      ],
                    },
                    [
                      _c(
                        "div",
                        {
                          staticClass: "product-option",
                          on: {
                            click: function ($event) {
                              return _vm.selectProductStage(
                                product.product_sub_category
                              )
                            },
                          },
                        },
                        [
                          _c("div", { staticClass: "picture" }, [
                            _c("picture", [
                              _c("source", {
                                attrs: {
                                  srcset: _vm.getProductImage(
                                    product.product_sub_category,
                                    "webp"
                                  ),
                                  type: "image/webp",
                                },
                              }),
                              _c("img", {
                                staticClass: "image",
                                attrs: {
                                  src: _vm.getProductImage(
                                    product.product_sub_category,
                                    "png"
                                  ),
                                  alt: "alt-image",
                                },
                              }),
                            ]),
                          ]),
                          _c("div", { staticClass: "product-text" }, [
                            _c("h3", [
                              _vm._v(
                                "\n              " +
                                  _vm._s(product.title) +
                                  "\n            "
                              ),
                            ]),
                            _c("div", {
                              domProps: {
                                innerHTML: _vm._s(product.description),
                              },
                            }),
                          ]),
                        ]
                      ),
                    ]
                  )
                }),
                _vm.skipProductsText
                  ? _c(
                      "b-button",
                      {
                        staticClass: "no-product",
                        attrs: {
                          variant: "default",
                          "aria-label": "skip products button",
                        },
                        on: { click: _vm.skipAddingProduct },
                      },
                      [
                        _vm._v(
                          "\n        " +
                            _vm._s(_vm.skipProductsText) +
                            "\n        "
                        ),
                        _c("fa-icon", {
                          staticClass: "fa-xs",
                          attrs: { icon: "chevron-right" },
                        }),
                        _vm._v(" \n      "),
                      ],
                      1
                    )
                  : _vm._e(),
              ],
              2
            ),
          ]
        : _c("ct-centered-spinner", [
            _vm._v("\n    " + _vm._s(_vm.loadingText) + "\n  "),
          ]),
    ],
    2
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }